@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}
.error {
  color: var(--failColor);
}
.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.title {
  composes: formMargins;
  flex-basis: 7%;
  padding: 4px 0px 0 5px;
  position: relative;
  min-height: 50px;
  margin: 16px 0px;
  & label {
    background-color: white;
    padding-left: 3px;
    padding-right: 3px;
    position: absolute;
    top: -4px;
    left: 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--matterColorGrey);
    margin-left: 15px;
  }
  & input,
  & textarea {
    border-bottom: none;
    font-size: 15px;
    font-weight: 300;
    background-color: white;
    padding-top: 0;
    padding: 8px 12px;
    border: 1px solid var(--marketplaceColor);
    transition: none;
    border-radius: var(--borderRadius8);
  }
  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
  & textarea {
    box-sizing: border-box;
    min-height: 136px !important;
    margin: 0px !important;
    padding: 12px !important;
    border: 1px solid var(--marketplaceColor);
    resize: vertical !important;
    height: unset !important;
  }
}
.invalidInput {
  & input,
  & textarea {
    border-color: var(--failColor);
  }
  & label {
    color: var(--failColor);
  }
}
.errorMessage {
  & > div:last-child {
    font-size: 14px !important;
    color: var(--failColor);
    font-weight: 400 !important;
    margin-top: 14px !important;
    margin-bottom: 0px !important;
    position: static !important;
  }
}

.submitButton {
  font-size: 16px;
  font-weight: 100;
  margin-top: 50px;
  margin-bottom: 56px;
  width: 181px;
  border-radius: 5px;
  min-height: 48px;
  @media (max-width: 375px) {
    width: 100%;
  }
  @media(max-width:575px){
    margin-top:25px;
  }
}
