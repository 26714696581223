@import '../../styles/customMediaQueries.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding:0 !important;
  /* background-color: var(--matterColorBright); */
  background-color: #0f0900;
  position: relative;

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 30px 0;
    border-top: none;
  }
  @media (max-width: 767px) {
    padding: 24px;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 50px;
  @media(min-width:1920px){
    max-width:1440px;
    margin:0 auto;
  }
  @media (--viewportMedium) {
    /* margin: 0 auto; */
  }
 
  @media (--viewportLarge) {
    /* max-width: 1128px; */
   
  }

  @media (--viewportXLarge) {
    /* max-width: 1056px; */
    /* padding: 0; */
  }
 @media(max-width:767px){
  padding:25px 15px 10px;
 }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  display: block;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
  @media (max-width: 767px) {
    flex-basis: 45%;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    margin-right: 0;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  width: 100%;
  height: 56px;
  margin: 0 0 15px;
  object-position: left;
  filter: contrast(1000%) invert(100%) sepia(100%) saturate(1%);
}

.organizationInfo {
}

.organizationDescription,
.organizationCopyright {
  composes: marketplaceTinyFontStyles from global;
  color: #fffffd;
  font-size: 16px;
  line-height: 24px;
  @media(max-width:575px){
    font-size:14px;
  }
}

.organizationCopyright {
  margin-top: 24px;
  text-align: center;
}

.copyrightLink {
  /* color: var(--matterColor); */
  color: #fffffd;

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
  @media (max-width: 767px) {
    flex-basis: 50%;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    margin-right: 0;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  composes: h5 from global;
  line-height: 20px;
  /* color: var(--matterColor); */
  color: #fffffd;
  font-size: 15px;
  font-weight: 100;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    /* color: var(--marketplaceColor); */
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
  @media (max-width: 767px) {
    flex-basis: 45%;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    margin-right: 0;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  /* flex-basis: 165px; */
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
  @media (max-width: 767px) {
    flex-basis: 45%;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    margin: 0;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}
.listTopElement {
  font-size: 22px;
  color: #fffffd;
  font-family: 'Josefin Sans';
}
.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.termsHeading {
  composes: h1 from global;
  margin: 0 0 19px 0;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}
.msgBox {
  min-height: 136px !important;
  margin: 0px 0px 50px !important;
  /* padding: 12px !important; */
  border: 1px solid var(--marketplaceColor);
  resize: vertical !important;
  height: unset !important;
  font-size: 15px;
  font-weight: 300;
  padding: 8px 12px;

  border-radius: var(--borderRadius8);
}
.modalHeading {
  font-family: var(--fontFamilyJosefin);
  font-size: 28px;
  font-weight: 600;
  color: #292929;
  margin-bottom: 20px;
  margin-top: 0;
}
.enquiryTxt {
  cursor: pointer;
}
.inputDiv {
  margin-bottom: 50px;
}

.formFields {
  /* width: calc(50% - 9px); */
  border: 1px solid #023462;
  border-radius: 8px;
  flex-basis: 48%;
  padding: 4px 40px 0 5px;
  height: 50px;
  position: relative;
  margin-bottom: 30px;
  & label {
    position: absolute;
    background-color: white;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: -15px;
    font-size: 14px;
    font-weight: 600;
    color: #b9b9b9;
    margin-left: 15px;
  }

  & input {
    border-bottom: none;
    font-size: 15px;
    font-weight: 300;
    background-color: white;
    padding: 4px 16px;
    /* margin: 4px 10px; */
  }
  & div {
    font-size: 15px;
    font-weight: 300;
    margin-top: 8px;
    position: absolute;
    white-space: nowrap;
  }
}
.enquiryMessage {
  flex-basis: 7%;

  position: relative;
  min-height: 50px;
  margin: 16px 0px;
  & label {
    position: absolute;
    background-color: white;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: -11px;
    font-size: 14px;
    font-weight: 600;
    color: #b9b9b9;
    margin-left: 20px;
  }

  & textarea {
    border-bottom: none;
    font-size: 15px;
    font-weight: 300;
    background-color: white;
    /* padding-top: 0; */
    padding: 8px 20px;
    border: 1px solid var(--marketplaceColor);
    transition: none;
    border-radius: var(--borderRadius8);
    /* min-height: 250px; */
    box-sizing: border-box;
  }
  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
  /* & input,
  & textarea {
    box-sizing: border-box;
    min-height: 136px !important;
    margin: 0px !important;
    padding: 12px !important;
    border: 1px solid var(--marketplaceColor);
    resize: vertical !important;
    height: unset !important;
  } */
}
.invalidInput {
  & input,
  & textarea {
    border-color: var(--failColor);
  }
  & label {
    color: var(--failColor);
  }
}

.errorMessage {
  margin-bottom: 40px;
}
.buttonDetail {
  background-color: #023462;
  color: #fffffd;
  width: 144px;
  font-size: 14px;
  border-radius: 8px;
  margin: 0;
  border: 0;
  padding: 5px 15px;
  cursor: pointer;
  min-height: 50px !important;
  &:hover {
    background-color: #000;
  }
  &:disabled {
    &:hover {
      background-color: var(--matterColorNegative);
    }
  }
}
.mapPanel {
  & > div {
    & > div {
      @media(max-width:575px){
        padding:25px 15px !important;
      }
      & > div {
        @media (max-width: 767px) {
          height: 100% !important;
        }
      }
    }
  }
}
.bottmFooter{
  display: flex;
  justify-content:center;
  align-items:center;
  background-color: #292929;
  padding: 10px 0;
  & p{
    font-size:16px;
    line-height:22px;
    color:rgb(255 255 255 / 70%);
    font-family:var(--fontFamilyAvenir);
    font-weight:400;
    margin-right:3px;
    margin-top: 0;
    margin-bottom: 0;
    & a{
      color:#fff;
      font-weight:700;
      margin-left: 5px;
      text-decoration:none;
      border-bottom:2px solid transparent;
      padding-bottom: 5px;
      &:hover{
        border-bottom:2px solid rgb(255 255 255 / 70%);
      }
    }
    @media(max-width:575px){
      font-size:14px;
      line-height:20px;
    }
  }
  @media(max-width:575px){
    padding: 10px 15px;
  }
}