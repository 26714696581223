.optionControl {
  border: 1px solid var(--marketplaceColor) !important;
  min-height: 54px !important;
  border-radius: var(--borderRadius8) !important;
  padding-left: 4px;
  width: 100%;
  margin-left: 0px;
  font-size: 16px;
}

.emptyOptionControl {
  & > div:first-child {
    padding: 8px 0px 12px;
    padding-left: 11px;
    & > div:first-child {
      color: var(--matterColorGrey);
      line-height: 19px;
      font-size: 16px;
      @media(max-width:575px){
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
}

.focusedControl {
  border: 1px solid var(--marketplaceColor) !important;
  box-shadow: none !important;
}

.selectMenu {
  z-index: 10 !important;
  border-radius: var(--borderRadius10) !important;
  box-shadow: var(--boxShadowDropDown) !important;
  top: unset !important;
  margin: 0px !important;

  & > div:first-child {
    padding: 0px !important;
    border-radius: var(--borderRadius10) !important;
    font-weight: 400 !important;
    & .singleOption:last-child {
      &::after {
        display: none;
      }
    }

    & .singleOption:first-child {
      border-top-left-radius: var(--borderRadius10);
      border-top-right-radius: var(--borderRadius10);
    }

    & .singleOption:last-child {
      border-bottom-left-radius: var(--borderRadius10);
      border-bottom-right-radius: var(--borderRadius10);
    }
  }
}

.singleOption {
  font-size: 16px !important;
  color: var(--matterColorBlack) !important;
  font-family: var(--fontFamilyAvenir) !important;
  padding: 16px 26px !important;
  position: relative !important;
  background-color: #fff !important;
  flex-grow: 1;
  &:hover {
    color: var(--marketplaceColorLight) !important;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 48px);
    height: 1px;
    background: var(--matterColorBlue) !important;
    z-index: 1;
  }
}

.multiOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative !important;
  padding: 18px 28px !important;
  background-color: #fff !important;

  & .mutliOptnLabel {
    margin-left: 8px;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: var(--matterColorBlack) !important;
    font-family: var(--fontFamilyAvenir) !important;
    line-height: 20px;
    position: relative;
    top: 3px;

    flex-grow: 1;
    &:hover {
      color: var(--marketplaceColorLight) !important;
    }
  }

  & .checkbox {
    width: 20px;
    height: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 48px);
    height: 1px;
    background: var(--matterColorBlue) !important;
    z-index: 1;
  }
}
.selectedMutliOption {
  background-color: var(--matterColorBlue) !important;
  margin-top: -1px !important;
}

.valueContainer {
  margin: 8px 0px 0px !important;

  & .value:nth-child(n + 3) {
    display: none;
  }
}
.moreThanTwoValueContainer {
  & > .value:nth-child(2) {
    margin-right: 24px;
    &::after {
      content: '...';
      position: absolute;
      bottom: 2px;
      right: -37px;
      font-size: 36px;
      color: var(--matterColorBlack);
    }
  }
}

.value {
  position: relative !important;
  /* transform: scale(0.9) translateX(-5%); */
  & > div {
    background-color: var(--successColor) !important;
    padding: 0px 4px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: var(--borderRadius5) !important;
    overflow-y: hidden;
    min-width: 125px !important;
    /* margin-right: 4px !important; */
    justify-content: space-between;
    & > div:first-child {
      color: #fff !important;
      font-family: var(--fontFamilyAvenir) !important;
      font-weight: 600 !important;
      letter-spacing: 0.2px !important;
      position: relative;
      top: 1px;
      max-width: 240px;
      @media(max-width:575px){
        max-width: 160px;
        font-size: 80%;
      }
    }

    & > div:last-child {
      background-color: var(--matterColorYellow) !important;
      padding: 2px !important;
      margin: 0px 4px;
      border-radius: 50% !important;
      & svg {
        width: 16px;
        height: 16px;
        fill: var(--successColor) !important;
        @media(max-width:575px){
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.emptyValueContainer {
  & > div:first-child {
    background-color: transparent !important;
  }
}

.dropDownIndicator {
  & svg {
    width: 15px !important;
    height: 15px !important;
    fill: var(--matterColorBlack) !important;

    & path {
      stroke: var(--matterColorBlack) !important;
    }
  }
  & .arrow {
    pointer-events: none;
  }
}

.selectedSingleOption {
  background-color: var(--matterColorBlue) !important;
  margin-top: -1px !important;
}
