@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}
.topDiv {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;

  @media (max-width: 575px) {
    padding-top: 0;
  }
}
.backbtn {
  border: none;
  color: #025196;
  cursor: pointer;
  &:hover {
    color: #000;
    text-decoration: underline;
  }
}
.infobtn {
  padding: 3px 25px;
  min-width: 172px;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: #f0f6fc;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: #292929;
    border-color: #292929;
  }
  @media (max-width: 767px) {
    padding: 10px 25px;
  }
}
.prpcls {
  border: 1px solid #025196;
  color: #025196;
}
.infodiv {
  display: block;
  & :nth-child(1) {
    margin-right: 20px;
  }
  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
    & :nth-child(1) {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
}
.price {
  font-size: 20px;
  font-weight: 800;
  color: #292929;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.bkngamt {
  font-weight: 600;
  font-size: 18px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.map {
  width: 100vw;
  height: 100vh;
}
.mapWrapper {
  width: 100%;
  height: 300px;
  border-radius: 20px;
}
.mapRootClassName {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
.mapbox {
  background: transparent;
}
.location {
  margin: 20px 0;
}
.title {
  text-transform: capitalize;
  color: #292929;
  line-height: 24px;
  font-size: 27px;
  font-weight: 700;
  margin: 0 0 2px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.address {
  color: #025196;
  margin: 0 0 15px;
}
.desc {
  color: #292929;
  word-break: break-all;
  margin: 35px 0;
  font-size: 20px;
  font-weight: 400;
}
.adoc {
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  width: 100%;
  justify-content: center;
}
.adoc span {
  margin: 0 15px;
  text-align: center;
  font-size: 16px !important;
  font-weight: 600 !important;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 270px;
}
.alldoc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 15px 0;
}
.docs {
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-bottom: 25px;
  }
}
.docs span {
  font-size: 20px;
  color: #292929;
  font-weight: 700;
}
.btmdiv {
  display: flex;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 15px;
  }
}
.imgsctn {
  flex-basis: 30%;
  margin: 0 30px 0 0;
  @media (max-width: 767px) {
    margin: 0px;
  }
}
.imggal {
  border-radius: 15px;
}
.img {
  width: 100%;
  /* height: 100%; */
  border-radius: 15px;
  object-fit: contain;
}
.wrapperClassName {
  & > :nth-child(1) {
    & > :nth-child(1) {
      border-radius: 15px;
    }
  }
}
.adocc {
  flex-basis: 45%;
  max-width: 45%;
  margin: 0 16px 24px 0;

  @media (max-width: 575px) {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}
.adocc:nth-child(2n) {
  margin-right: 0;
}
.authordet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  & > span:first-child {
    color: #292929 !important;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    margin: 0 0 5px;
  }
  & > span:last-child {
    color: #b9b9b9 !important;
    line-height: 22px;
    font-size: 14px;
  }
}
.rating {
  display: flex;
  flex-direction: column;
  @media (max-width: 575px) {
    margin: 15px 0 0;
  }
}
.rating span {
  font-size: 14px;
  font-weight: 600;
}
.startrate svg {
  margin: 0 2px;
}
.authorsec {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  border-bottom: 2px solid #f0f6fc;
  border-top: 2px solid #f0f6fc;
  padding: 10px 0;

  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.authorseco {
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
  border-bottom: 4px solid #f0f6fc;
  border-top: 4px solid #f0f6fc;
  padding: 10px 0;
}
.authorinfo {
  display: flex;
}
.bookButton {
  display: block;
  max-width: 220px;
  font-family: var(--fontFamilyAvenir);
  min-height: 47px;
  background-color: #023462;
  color: #eee;
  border-radius: 7px;
  margin: 45px auto 30px 0;
  font-size: 21px;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 18px;
    margin: 45px auto 0 0;
  }
  @media (max-width: 430px) {
    max-width: 100%;
  }
}
.secdiv {
  width: 100%;
  flex-basis: 70%;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
}
.infoone {
  display: flex;
  width: 100%;
  font-family: var(--fontFamilyAvenir);
  font-size: 20px;
  margin-bottom: 20px;
  @media (max-width: 575px) {
    flex-direction: column;
    font-size:18px;
  }
  & > span:first-child {
    max-width: 40%;
    flex-basis: 40%;
    color: #b9b9b9;
    @media (max-width: 575px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  & > span:last-child {
    color: #292929;
    word-break: break-word;
    max-width: 60%;
    flex-basis: 60%;
    @media (max-width: 575px) {
      max-width: 100%;
      flex-basis: 100%;
      font-size: 16px;
      margin: 5px 0 0;
    }
  }
}
.maindiv {
  flex-basis: 100%;
  max-width: 100%;

  @media (max-width: 1023px) {
    padding: 0 30px;
  }
  @media (max-width: 575px) {
    padding: 0 15px;
  }
}
.allinfo {
  margin: 40px 0;
  padding-bottom: 30px;
}
.adoc svg {
  flex-shrink: 0;
  width: 20px;
  height: 22px;
}
.smlscdiv {
  margin: 30px 0 0 0;
}
.cpmspn {
  font-size: 20px;
  font-weight: 600;
  color: #292929;
  text-decoration: underline;
  font-family: var(--fontFamilyAvenir);
}
.modaldiv {
}
.modaldiv > p:first-child {
  font-size: 24px;
  font-weight: 600;
  font-family: var(--fontFamilyAvenir);
  margin: 0;
}
.modaldiv > p:nth-child(2) {
  margin: 0;
  line-height: 24px;
  font-family: var(--fontFamilyAvenir);
  @media (max-width: 767px) {
    margin-top: 15px;
  }
}
.modaldiv > div {
  margin: 20px 0;
}
.modaldiv > div > div > span:last-child {
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 600;
  color: #292929;
}
.modaldiv > div > div > span:first-child {
  font-size: 16px;
}
.zeroinf {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  & span:first-child {
    color: #000;
    font-weight: 700;
  }
  & span:last-child {
    color: #023462;
    cursor: pointer;
  }
}
.zerbtn {
  padding: 5px 20px;
  background-color: #023462;
  color: #eee;
  border-radius: 7px;
  margin: 30px auto 16px 0;
  font-size: 19px;
  font-weight: 500;
  @media (max-width: 430px) {
    max-width: 100%;
  }
}
.paypalModal {
  @media (max-width: 767px) {
    top: 0 !important;
    position: fixed !important;
    min-height: 100vh !important;
  }
  & > div {
    & > div {
      @media (max-width: 767px) {
        padding-bottom: 0 !important;
      }
    }
  }
}
