@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
}

.mapRoot {
  width: 100%;
  height: 100%;
}

.reusableMap {
  height: 100%;
  width: 78vw;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.defaultMapLayout {
  width: 100%;
  height: 450px;
  @media(max-width:767px){
    height: 100%;
  }
}

/**
 * When reusable map is attached right to the body it's hidden.
 * Specificity rule is added to overwrite positioning coming from props.className
 */
body > .reusableMapHidden {
  position: absolute;
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  opacity: 0;

  @media (--viewportMedium) {
    top: -1000px;
    left: -1000px;
    right: auto;
  }
}
