@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.input {
  flex-shrink: 0;
  margin: 16px 0px;
  position: relative;

  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border: 1px solid var(--marketplaceColor);
    padding: 12px 18px;
    box-sizing: border-box;
    border-radius: var(--borderRadius8);
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
  }
  & label {
    font-weight: 400 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: var(--matterColorGrey);
    font-size: 14px !important;
  }
  & textarea {
    min-height: 136px;
    resize: vertical !important;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}
.invalidInput {
  & input,
  & textarea {
    border-color: var(--failColor);
  }
  & label {
    color: var(--failColor);
  }
}
.FinancialInfoTopGap {
  margin-top: 20px !important;
}
.title {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 12px;
  color: var(--matterColorBlack);

  @media (--viewportLarge) {
    padding: 1px 0 7px 0;
  }
}
.submitButton {
  font-size: 16px;
  font-weight: 100;
  margin-top: 50px;
  margin-bottom: 56px;
  width: 181px;
  border-radius: 5px;
  min-height: 48px;
  @media (max-width: 375px){
    width: 100%;
  }
  
}
