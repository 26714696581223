.root {
  display: block;
  float: left;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.wrapper {
  position: relative;
}

.removeImage {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: #fdb338;
  border: none;
  border-radius: 18px;
  /* border-bottom-left-radius: 2px; */
  cursor: pointer;

  & svg {
    position: absolute;
    top: 10px;
    left: 11px;
    width: 13px;
    height: 13px;
    fill: #292929;
    stroke: #292929;
  }

  &:hover svg {
    fill: var(--matterColorLight);
    stroke: var(--matterColorLight);
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.thumbnailLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(211, 211, 211, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
