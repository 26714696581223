@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 50px 100px;
  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
  @media(min-width:1920px){
    max-width:1440px;
    margin:0 auto;
  }
  @media (max-width: 1023px) {
    width: 100%;
    padding: 50px 0;
  }
  /* @media (min-width: 992px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 768px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  } */
  @media(max-width:767px){
    padding: 30px 0;
  }
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
  /* @media(min-width:1920px){
    max-width:1440px;
    margin:0 auto;
  } */
}

.nav {
  /* Layout */

  flex-shrink: 0;
  /* background-color: var(--matterColorLight); */
  background-color: #f7fafd;

  box-shadow: var(--boxShadowLight);
  /* overflow: hidden; */
  padding: 15px;
  /* min-width: 30%; */
  flex: 0 0 30%;
  width: 30%;
  @media (max-width: 1023px) {
    overflow-x: auto;
    padding: 0;
    display: flex;
    flex: 0 0 100%;
    width: 100%;
    padding:8px;
  }
  /* & > div:first-child{
    & > a{
      @media(max-width:1200px){
        width:auto !important;
      }
     
    }
  } */
  @media(max-width:1200px){
    flex: 0 0 40%;
    width: 40%;
  }
  @media(max-width:1023px){
    flex:0 0 100%;
    width:100%;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;
  @media (max-width: 991px) {
    padding: 0 10px !important;
  }
  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    display: none; /*We dont want to show the last tab*/
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
      & a{
        padding-right:5px;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }
  @media(max-width:767px){
    margin-left:0;
    padding: 0 5px !important;
  }
}
.tab a {
  position: relative;
  font-size: 16px;
  @media(max-width:991px){
    padding-bottom:0;
  }
}
.tab a::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -9px;
  left: 0;
  background-color: var(--matterColorBlue);
}
.hideNav {
  display: none;
}
.panel {
  /* flex-grow: 1; */
  padding: 50px 30px 150px;
  flex:0 0 70%;
  width:70% !important;
  @media (--viewportLarge) {
    /* padding: 88px 36px 82px 59px; */
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 88px calc((100% - 1056px) / 2) 82px 59px; */
  }
  @media(max-width:1200px){
    flex:0 0 60%;
    width:60% !important;
    padding:35px 30px 50px 30px;
  }
  @media(max-width:1023px){
    flex:0 0 100%;
    width:100% !important;
  }
  @media(max-width:767px){
   padding:30px 15px 0;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  font-size: 18px;
  line-height: 32px;
}
.modalBtn {
  display: block;
  margin: 24px auto 16px;
  background-color: var(--marketplaceColor);
  padding: 12px 0px;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  text-transform: capitalize;
  font-weight: 400;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: var(--marketplaceColorDark);
  }
}
.detailsSelect {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.bckhead {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 20px;
  /* @media(min-width:1920px){
    max-width:1440px;
    margin:0 auto;
  } */
  @media (max-width: 1023px) {
    padding: 0 15px;
    margin-top:0;
  }
  @media(max-width:991px){
    margin-bottom: 20px;
  }
}
.bckbtn {
  margin: 0 auto 20px 0;
  border: none;
  color: #025196;
  cursor: pointer;
  margin-right: auto;
  align-items: center;
  font-size: 18px;
  &:hover {
    color: #000;
    text-decoration: underline;
  }
  @media(max-width:1199px){
    margin:0 auto 10px 0;
  }
  @media(max-width:767px){
    margin:0;
    text-align:left;
  }
}
.heading {
  font-family: var(--fontFamilyJosefin);
  font-size: 30px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid var(--matterColorBlue);
  @media(max-width:767px){
    font-size:25px;
    line-height:35px;
  }
}
