@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
  /* padding-bottom: 90px; */
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
  }
}

.imageWrapperForSectionImage {
  composes: heroHeight;
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}
.lwmn {
  padding: 0 100px;
  @media (max-width: 1360px) {
    padding: 0 50px;
  }

  @media (max-width: 991px) {
    padding: 0 30px;
  }
  @media (max-width: 767px) {
    padding: 0 15px !important;
  }
  /* @media (max-width: 575px) {
    padding: 0 30px !important;
  } */
  @media (min-width: 1920px) {
    max-width: 1440px;
    margin: 0 auto;
  }
}
.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 348px);
    flex-basis: calc(100% - 348px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 300px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: 1056px;
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: 1128px;
    padding: 64px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left: 1px solid var(--matterColorNegative);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  @media (--viewportLarge) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
    position: sticky;
    top: 108px;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionTitle {
  /* Font for sections titles */
  composes: h3 from global;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.11px;
}

.detailsTitle,
.descriptionTitle {
  composes: sectionTitle;
  margin-top: 0;
  margin-bottom: 12px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.description {
  padding: 1px 0 5px 0;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--fontWeightRegular);

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionDetails {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--matterColorNegative);
  }

  @media (--viewportMedium) {
    padding: 7px 0 0px 0;
    line-height: 40px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  composes: sectionTitle;

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  composes: sectionTitle;

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    padding-bottom: 5px;
    margin-bottom: 0;
  }
}

.reviewsTitle {
  composes: sectionTitle;

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    padding: 5px 0 3px 0;
    margin: 56px 0 26px 0;
  }
}

.sectionAuthor {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.aboutProviderTitle {
  composes: sectionTitle;

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 64px 0 24px 0;
    padding: 5px 0 3px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 72px 0;
    padding-bottom: 4px;
  }
}

.productMobileHeading {
  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}
.topDiv {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;

  /* flex-direction: column; */

  /* @media (max-width: 575px) {
    padding-top: 0;
  } */

  /* & > div {
    display: flex;
  }
  & > div:nth-child(2) {
    margin-left: auto;
  } */
}
.backbtn {
  border: none;
  color: #025196;
  cursor: pointer;
  &:hover {
    color: #000;
    text-decoration: underline;
  }
}
.infobtn {
  padding: 3px 25px;
  min-width: 172px;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: #f0f6fc;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #292929;
    border-color: #292929;
  }
  @media (max-width: 767px) {
    padding: 10px 25px;
  }
}
.prpcls {
  border: 1px solid #025196;
  color: #025196;
}
.infodiv {
  display: block;
  & :nth-child(1) {
    margin-right: 20px;
  }
  @media (max-width: 575px) {
    & :nth-child(1) {
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
    /* margin-right:0; */
    & :nth-child(1) {
      margin-right: 0;
      /* margin-bottom: 20px; */
    }
  }
}
/* .price {
  font-size: 20px;
  font-weight: 800;
  color: #292929;
} */
.prcsctn {
  display: flex;
  border-bottom: 4px solid #f0f6fc;
  padding-bottom: 8px;
  & > a:nth-child(2) {
    /* margin-top: 3px; */
    margin-left: auto;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    font-family: 'Josefin Sans';
    color: #025196 !important;
    border-bottom: 1px solid transparent;

    & > svg {
      margin-right: 8px;
      width: 20px;
      height: 20px;
      margin-top: -6px;
      @media (max-width: 991px) {
        width: 15px;
        height: 15px;
        margin-top: -7px;
      }
      @media (max-width: 575px) {
        width: 12px;
        height: 12px;
        margin-right: 3px;
      }
    }

    &:hover {
      text-decoration: none;

      & > span {
        cursor: pointer;
        color: #292929;
        border-bottom: 1px solid #292929;
      }

      & svg {
        cursor: pointer;

        & path {
          stroke: #292929;
        }
      }

      &::after {
        display: inline;
      }
    }
    @media (max-width: 991px) {
      font-size: 16px;
    }
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
}
.editListingLink {
}
.bkngamt {
  font-size: 22px;
  font-weight: 800;
  color: #292929;
  flex-basis: 70%;
  @media (max-width: 991px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
}
.map {
  width: 100vw;
  height: 100vh;
}
.mapWrapper {
  width: 100%;
  height: 300px;
  border-radius: 20px;
}
.mapRootClassName {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
.mapRootClassName canvas {
  width: 100% !important;
  height: 100% !important;
}
.mapbox {
  background: transparent;
}
.location {
  margin: 20px 0;
}
.title {
  text-transform: capitalize;
  color: #292929;
  line-height: 38px;
  font-size: 24px;
  margin: 0 0 2px;
  font-weight: 700;
  @media (max-width: 575px) {
    font-size: 20px;
  }
}
.address {
  color: #025196;
  margin: 0 0 15px;
  display: flex;
  align-items: baseline;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.desc {
  color: #292929;
  /* word-break: break-all; */
  margin: 35px 0;
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
}
.adoc {
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  width: 100%;
  justify-content: center;
}
.adoc span {
  margin: 0 15px;
  text-align: center;
  font-size: 16px !important;
  font-weight: 600 !important;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 270px;
}
.alldoc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 15px 0;
}
.docs {
  margin-bottom: 50px;
}
.docs span {
  font-size: 20px;
  color: #292929;
  font-weight: 700;
}
.btmdiv {
  display: flex;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.imgsctn {
  flex-basis: 40%;
  margin: 0 30px 0 0;
  @media (max-width: 767px) {
    margin: 0px;
  }
  & > div {
    & > div {
      & > div:nth-child(2) {
        & > div {
          & > nav {
            & button {
              @media (max-width: 767px) {
                width: 92px !important;
              }
              @media (max-width: 575px) {
                width: 82px !important;
              }
            }
          }
        }
      }
    }
  }
}
.imggal {
  border-radius: 15px;
}
.img {
  width: 100%;
  /* height: 100%; */
  border-radius: 15px;
  object-fit: contain;
}
.wrapperClassName {
  & > :nth-child(1) {
    & :nth-child(1) {
      border-radius: 15px;
      padding: 0;
      /* margin-top: 1px; */
      @media (max-width: 991px) {
        /* width: 94px !important; */
        /* margin-top: 10px !important; */
      }
    }
    & :nth-child(2) {
      margin-top: 4px;
    }
  }
}
.adocc {
  flex-basis: 45%;
  max-width: 45%;
  margin: 0 16px 24px 0;

  @media (max-width: 575px) {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}
.adocc:nth-child(2n) {
  margin-right: 0;
}
.authordet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  & > span:first-child {
    color: #292929 !important;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    margin: 0 0 5px;
    @media (max-width: 575px) {
      font-size: 16px;
    }
  }
  & > span:last-child {
    color: #b9b9b9 !important;
    line-height: 22px;
    font-size: 14px;
  }
  @media (max-width: 575px) {
    margin-left: 6px;
    justify-content: flex-start;
  }
}
.rating {
  display: flex;
  flex-direction: column;
  @media (max-width: 575px) {
    margin: 15px 0 0;
  }
}
.rating span {
  font-size: 14px;
  font-weight: 600;
}
.startrate svg {
  margin: 0 2px;
}
.authorsec {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  border-bottom: 2px solid #f0f6fc;
  /* border-top: 2px solid #f0f6fc; */
  padding: 10px 0;

  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.authorseco {
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
  border-bottom: 4px solid #f0f6fc;
  /* border-top: 4px solid #f0f6fc; */
  padding: 10px 0;
}
.authorinfo {
  display: flex;
  width: 100%;
  align-items: center;
}
.bookButton {
  display: block;
  max-width: 220px;
  font-family: var(--fontFamilyAvenir);
  min-height: 47px;
  background-color: #023462;
  color: #eee;
  border-radius: 7px;
  margin: 45px auto 30px 0;
  font-size: 21px;
  font-weight: 500;
  @media (max-width: 430px) {
    max-width: 100%;
  }
}
.bookButton svg circle {
  stroke: #fff;
}
.secdiv {
  width: 100%;
  flex-basis: 60%;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
}
.verifiedLabel {
  margin-left: 8px;
  color: var(--successColorDark);
  background-color: #defceb;
  padding: 5px 8px;
  font-size: 16px;
}
.notVerifiedLabel {
  margin-left: 8px;
  color: var(--failColor);
  background-color: #fef3f3;
  padding: 5px 8px;
  font-size: 16px;
  flex: 0 0 20%;
  width: 20%;
  text-align: center;
  /* @media(min-width:1920px){
    flex: 0 0 20%;
    width: 20%;
  } */
  @media (max-width: 991px) {
    font-size: 15px;
    padding: 5px;
    flex: 0 0 25%;
    width: 25%;
  }
  @media (max-width: 767px) {
    margin-left: 0;
    font-size: 14px;
    flex: 0 0 100%;
    width: 100%;
    max-width: 200px;
    margin-top: 10px;
  }
}
.infoone {
  display: flex;
  width: 100%;
  font-family: var(--fontFamilyAvenir);
  font-size: 20px;
  margin-bottom: 20px;
  @media (max-width: 575px) {
    flex-direction: column;
  }
  & svg {
    width: 18px;
    height: 18px;
    fill: green;
  }
  & > span:first-child {
    max-width: 40%;
    flex-basis: 40%;
    color: #b9b9b9;
    @media (max-width: 575px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  & > span:last-child {
    color: #292929;
    word-break: break-word;
    max-width: 60%;
    flex-basis: 60%;
    &:first-letter {
      text-transform: capitalize;
    }
    @media (max-width: 575px) {
      max-width: 100%;
      flex-basis: 100%;
      font-size: 18px;
      margin: 5px 0 0;
    }
  }
}
.maindiv {
  /* @media (max-width: 1023px) {
    padding: 0 30px;
  }
  @media (max-width: 575px) {
    padding: 0 15px;
  } */
}
.allinfo {
  margin: 40px 0;
  padding-bottom: 30px;
  @media (max-width: 767px) {
    padding-bottom: 0;
  }
}
.adoc svg {
  flex-shrink: 0;
  width: 20px;
  height: 22px;
}
.smlscdiv {
  margin: 30px 0 0 0;
}
.verspn,
.verspnRed {
  font-size: 16px;
  font-weight: 200;
  background-color: #4db595;
  border-radius: 5px;
  margin-left: 15px;
  padding: 5px 8px;
  align-items: center;

  color: #fff;
  white-space: nowrap;
  @media (max-width: 991px) {
    font-size: 14px;
  }
  & svg {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: -3px;
    @media (max-width: 991px) {
      width: 17px;
      height: 17px;
    }
  }
}
.verspnRed {
  background-color: rgb(251, 217, 217);
  color: #000000;
  border: 1px solid #000000;
  & svg {
    width: 15px;
    height: 15px;
  }
}
.closeListing {
  border: none;
  color: #025196;
  cursor: pointer;
  margin-left: auto;
  &:hover {
    color: #000;
    /* text-decoration: underline; */
  }
}
.rtedv {
  /* margin-left: auto; */
  margin-top: -8px;
  margin-bottom: 12px;
  & > svg {
    height: 15px;
    width: 17px;
  }
}
.ratingDiv {
  margin-left: auto;
  justify-content: center;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  & > span:nth-child(1) {
    color: #025196;
    font-size: 16px;
  }
  @media (max-width: 575px) {
    justify-content: flex-start;
    margin-top: -2px;
  }
}
.ratedv {
  & svg {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }
  & svg:last-child {
    margin-right: 0px;
  }
}
.addrssIcon {
  margin-top: -2px;
  & > svg {
    @media (max-width: 767px) {
      margin-top: -3px;
    }
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
}
.addrssInfo {
  @media (max-width: 991px) {
    font-size: 13px;
  }
}
.addressWrap {
  display: flex;
  flex: 0 0 80%;
  width: 80%;
  /* @media(min-width:1920px){
    flex: 0 0 80%;
    width: 80%;
  } */
  @media (max-width: 991px) {
    flex: 0 0 75%;
    width: 75%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    width: 100%;
  }
}
