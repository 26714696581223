@import '../../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* This is the title for the Edit Photos component */
.title {
  margin: 10px 0 0 0;
  font-family: var(--fontFamilyAvenir);
  font-size: 25px;
  line-height: 29px;
  @media (--viewportLarge) {
    margin-bottom: 2px;
    padding: 1px 0 7px 0;
    font-size: 30px;
    font-weight: 600;
    margin-top: -25px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    margin: 10px 0;
  }
}
.subhead {
  font-size: 15px;
  color: #292929;
  line-height: 17px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
}
