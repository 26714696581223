@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.deliveryOption {
  margin-left: 30px;
  @media (--viewportMedium) {
    margin-left: 60px;
  }
}

.deliveryCheckbox {
  margin-bottom: 24px;
}

.disabled {
  color: var(--matterColorAnti);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.input {
  flex-shrink: 0;
  margin: 16px 0px;
  position: relative;

  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border: 1px solid var(--marketplaceColor);
    padding: 12px 18px;
    box-sizing: border-box;
    border-radius: var(--borderRadius8);
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorBlack);
  }
  & label {
    font-weight: 400 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: var(--matterColorGrey);
    font-size: 14px !important;
  }
  & textarea {
    min-height: 136px;
    resize: vertical !important;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}

.invalidInput {
  & input,
  & textarea {
    border-color: var(--failColor);
  }
  & label {
    color: var(--failColor);
  }
}

.submitButton {
  font-size: 16px;
  font-weight: 100;
  margin-top: 50px;
  margin-bottom: 56px;
  width: 181px;
  border-radius: 5px;
  min-height: 48px;
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 0;
    margin-top:20px;
  }
}

.sectionHeading {
  margin-top: 0 !important;
  font-weight: 500;
  color: var(--matterColorBlack);
  font-size: 25px;
  @media (--viewportLarge) {
    font-size: 30px;
    font-weight: 600;
  }
}

.selectDiv {
  position: relative;
  margin: 28px 0px;

  & .selectLabel {
    position: absolute;
    top: -7px;
    left: 16px;
    z-index: 1;
    background: #fff;
    display: inline-block;
    padding: 0px 4px;
    font-size: 14px;
    line-height: 17px;
    color: var(--matterColorGrey);
    font-weight: 400 !important;
  }
}

.textareaDiv {
  position: relative;
  margin: 32px 0px 20px;

  & .subIcon {
    position: absolute;
    bottom: 40px;
    right: 8px;
    pointer-events: none;
    color: var(--matterColorBlack);
  }

  & .helpText {
    color: var(--matterColorBlack);
    font-size: 13px;
    line-height: 17px;
    margin-top: 0;
    margin-left: 4px;
    font-weight: 400;
    display: block;
  }
}

.invalidTextArea {
  & .subIcon {
    bottom: 54px;
  }
}
.inputDiv {
  & .privateMsgDiv {
    color: var(--matterColorBlack);
    font-size: 13px;
    line-height: 17px;
    margin-top: -8px;
    margin-left: 4px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & svg {
      margin-right: 8px;
    }
  }
}

.privateTextareaDiv {
  & .subIcon {
    bottom: 45px;

    /* @media (max-width: 410px) {
      bottom: 61px;
    } */
    @media(max-width:1200px){
      bottom: 60px;
    }
    @media(max-width:575px){
      bottom: 80px;
    }
  }
  & .privateMsgDiv {
    color: var(--matterColorBlack);
    font-size: 13px;
    line-height: 17px;
    margin-left: 4px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 4px;

    & svg {
      margin-right: 8px;
    }
  }
}

.invalidPrivateTextarea {
  & .subIcon {
    bottom: 75px;
    @media (max-width: 410px) {
      bottom: 91px;
    }
  }
}

.financialSubheading {
  line-height: 21px;
  font-size: 14px;
  font-weight: 400;
  color: var(--matterColorBlack);
}

.businessDateField {
  margin-top: -28px;
  & label {
    font-weight: 400 !important;
    background-color: #fff;
    position: relative;
    top: 12px;
    left: 16px;
    padding: 0px 4px;
    color: var(--matterColorGrey);
    font-size: 14px !important;
    z-index: 22;
    display: inline-block;
  }
  & > div:nth-child(2) {
    position: relative;
    z-index: 21;

    & input {
      border-bottom: 0px;
      border: 1px solid var(--marketplaceColor);
      padding: 12px 18px;
      box-sizing: border-box;
      border-radius: var(--borderRadius8);
      font-weight: 400 !important;
      font-size: 16px;
      line-height: 20px;
      color: var(--matterColorBlack);
      min-height: 54px;
    }
  }
  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}

.invalidBusinessDateField {
  & input {
    border-color: var(--failColor) !important;
  }
  & label {
    color: var(--failColor);
  }
}

.pickerRootClass {
  margin-left: 0px !important;
  & > div:first-child {
    & > div:first-child {
      & > div:first-child {
        & > div:nth-child(2) {
          top: 53px !important;

          border-radius: var(--borderRadius8) !important;
          font-family: var(--fontFamilyJosefin);
          font-weight: 400 !important;

          & > div:first-child {
            border-radius: var(--borderRadius8) !important;
          }
          @media (max-width: 344px) {
            left: calc(50vw - 172px) !important;
          }
        }
      }
    }
  }
}

.singleOption,
.Control {
  font-weight: 400 !important;
}
.Control {
  max-height: 54px;
  padding-top: 0px !important;

  & > div:first-child {
    padding-top: 0px !important;
    padding-left: 12px !important;
  }
}

.DropdownIndicator {
}

.IndicatorsContainer {
  max-height: 52px !important;
}
.bnsdate {
  position: relative;
  border: 1px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: var(--borderRadius8);
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  & label {
    font-weight: 400 !important;
    background-color: #fff;
    position: relative;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: var(--matterColorGrey);
    font-size: 14px !important;
    /* z-index:22; */
    display: inline-block;
    width: fit-content;
  }
  & div::before {
    border: none !important;
    border-bottom: none !important;
    &:hover {
      border-bottom: 0px !important;
    }
  }
  & div::after {
    border: none;
    border-bottom: none;
    &:hover {
      border-bottom: none !important;
    }
  }
  & div {
    &:hover {
      border-bottom: none;
    }
  }
}
.businessdte {
  border: none;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-bottom: 5px !important;
  & input {
    padding-top: 2px;
  }
  &:hover {
    border-bottom: none;
  }
}
.businessdte input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
