@import '../../styles/customMediaQueries.css';

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 29px 24px 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 24px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 113px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
  @media (--viewportLargeWithPaddings) {
    padding: 113px 0 82px calc((100% - 1056px) / 2);
  }
}

.title {
  /* Font */
  color: var(--matterColor);
  font-family: 'Josefin Sans';
  font-size: 22px;

  /* Layout */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding-bottom: 10px;
    margin-top: 0;
  }

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.tabs {
  padding-top: 2px;

  display: flex;
  flex-direction: row;
  overflow-x: auto;

  @media (--viewportMedium) {
    padding-top: 12px;
    padding-top: 20px;
  }

  @media (--viewportLarge) {
    padding-top: 53px;
    flex-direction: column;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px;
  text-align: center;

  @media (--viewportLarge) {
    margin-top: 6px;
  }
}

.itemList {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  min-height: 50vh;
  min-width: 100%;
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  background: #dbe8f941;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 20px;

  /* border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative); */

  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {
    margin-bottom: 16px;
    padding-bottom: 7px;
  }
}

.avatarRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* padding-bottom: 20px; */
}

.item {
  /* display: flex;
  flex-direction: row; */
}

.itemLink {
  flex: 1;

  /* Layout */
  /* display: flex; */

  /* Remove text underline from this link */
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.itemAvatar {
  display: flex;
  align-items: center;
  margin-right: 15px;

  @media (--viewportMedium) {
    display: block;
    align-items: unset;
  }

  @media (--viewportLarge) {
    margin-right: 20px;
  }
}

.rowNotificationDot {
  width: 6px;
  height: 6px;
  margin-top: 11px;
  margin-right: 8px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 5px;
    margin-right: 13px;
    width: 9px;
    height: 9px;
  }
}

.notificationDot {
  color: var(--matterColorLight);

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--failColor);
}

.itemInfo {
  composes: h5 from global;
  display: flex;
  justify-content: center;
  padding: 15px 0;

  font-weight: var(--fontWeightSemiBold);
  text-decoration: none;

  /* Layout */
  /* flex-grow: 1; */
  flex: 1;
  flex-direction: column;

  margin-top: 5px;
  margin-bottom: 0;
  margin-right: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: none;
  }

  color: var(--matterColor);
}

.itemUsername {
  /* Font */
  composes: h5 from global;
  font-family: 'Josefin Sans';
  font-weight: 600;
  color: #292929;
  font-size: 18px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    padding: 3px 0 5px 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.itemEmail {
  /* font-family: 'Josefin Sans'; */
  font-weight: 400;
  color: #292929;
  font-size: 13px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemOrderInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  line-height: 30px;

  padding: 10px 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* line-height: 30px; */

    padding: 15px 0;
    margin-bottom: 0;
  }
}

.bookingInfoWrapper {
  composes: marketplaceTinyFontStyles from global;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 14px;
  margin-top: 2px;
  padding-top: 2px;

  @media (--viewportMedium) {
    padding-top: 0px;
    margin-top: 2px;
    line-height: 16px;
  }
}

.itemPrice {
  &::before {
    font-size: 10px;
    margin: 0 6px;
    display: inline-block;
    content: '\25CF'; /* middot */
  }
}

.itemState {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: normal;

  margin-top: 6px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.lastTransitionedAt {
  composes: marketplaceTinyFontStyles from global;

  margin: 0;
  text-align: right;
  color: var(--matterColor);
}

.pagination {
  margin: 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
}

.stateActionNeeded {
  font-weight: var(--fontWeightMedium);
  color: var(--attentionColor);
}

.stateNoActionNeeded {
  color: var(--matterColor);
}

.stateConcluded {
  color: var(--matterColorAnti);
}

.bookingActionNeeded {
  color: var(--matterColor);
  margin-top: 4px;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.bookingNoActionNeeded {
  color: var(--matterColorAnti);
}

.lastTransitionedAtEmphasized {
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.lastTransitionedAtNotEmphasized {
  color: var(--matterColorAnti);
}

.bannedUserLink {
  & .itemUsername,
  &:hover .itemUsername,
  & .bookingInfo,
  & .stateName,
  & .lastTransitionedAt {
    color: var(--matterColorAnti);
  }
}

.inboxContainer {
  display: block;
  margin: auto;
  width: 95%;
  max-width: 99vw;
  min-width: clamp(290px, 55vw, 750px);
  padding: 0 100px;
  @media (max-width: 991px) {
    padding: 0 30px !important;
  }
  @media(max-width:767px){
    width: 100%;
    max-width: 100%;
    padding: 0 15px !important;
  }
}

.backbtn {
  border: none;
  color: #025196;
  cursor: pointer;
  &:hover {
    color: #000;
    text-decoration: underline;
  }
}

.topdv {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
}

.titleContainer {
  margin-bottom: 30px;
}

.horizontalLine {
  height: 1.5px;
  background-color: #02519615;
  border: none;
}

.priceText {
  font-size: 18px;
  /* font-family: 'Josefin Sans'; */
  font-weight: 600;
}

.listingTitle {
  font-size: 18px;
  /* font-family: 'Josefin Sans'; */
  font-weight: 500;
  color: #000;
}

.listingAddress {
  font-size: 14px;
  /* font-family: 'Josefin Sans'; */
  font-weight: 400;
  line-height: 20px;
  margin: 5px 0;
  @media (--viewportMedium) {
    margin: 0;
  }
}

.createQuoteButton {
  border: 1px solid #222d4b;
  padding: 3px 15px;
  min-width: 120px;

  text-align: center;
  border-radius: 5px;
  color: #fff;
  background: #023462;
  margin: 20px 0;
  font-size: 14px;
  &:hover {
    color: #023462;
    cursor: pointer;
    background: #fff;
  }
  &:disabled {
    background: #bbbbbb;
    color: #ececec;
    border: 1px solid #bbbbbb;
    cursor: not-allowed;
  }
}
