@import '../../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;
  font-size: 25px;

  @media (--viewportLarge) {
    margin-bottom: 7px;
    padding: 1px 0 7px 0;
    font-size: 30px;
    font-weight: 600;
    margin-top: -25px;
    color: var(--matterColorBlack);
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}
.subscriptionMessage {
  margin-top: -24px;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 200;
  color: #292929;
  @media (max-width: 767px) {
    margin-top: 0;
  }
}
