@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.deliveryOption {
  margin-left: 30px;
  @media (--viewportMedium) {
    margin-left: 60px;
  }
}

.deliveryCheckbox {
  margin-bottom: 24px;
}

.disabled {
  color: var(--matterColorAnti);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.input {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.allPackages {
  display: flex;

  justify-content: space-between;
  & .singlePackages {
    border: 1px solid transparent;
    box-shadow: 0px 1px 20px #00000014;
    margin: 0 20px 0 0;
    padding: 15px;
    border-radius: 7px;
    flex-basis: 32%;
    transition: all 0.05s ease-in-out;
    @media (max-width: 612px) {
      margin: 0 0 20px 0;
    }
    & .blutop {
      border-bottom: 1px solid #4789e0;
    }
    & .goltop {
      border-bottom: 1px solid #bca10f;
    }
    & .siltop {
      border-bottom: 1px solid silver;
    }
    & .packageTop {
      margin-bottom: 10px;
      color: #292929;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* @media (max-width: 612px) {
        display: flex;
        justify-content: space-between;
      } */
      @media (max-width: 767px) {
        padding-bottom: 10px;
      }
    }
    &:hover {
      border: 1px solid #023462;
    }
  }
  & .startingMonth {
    & > div:nth-child(1) {
      font-size: 13px;
      font-weight: 200;
    }
    & .monthlyFee {
      margin-top: -7px;
      @media (max-width: 767px) {
        margin-top: 0;
      }

      & > span:nth-child(1) {
        font-size: 24px;
        font-weight: 900;
        padding-right: 2px;
        color: #292929;
      }
      & > span:nth-child(2) {
        font-size: 12px;
        font-weight: 200;
      }
    }
  }
  @media (max-width: 612px) {
    flex-direction: column;
  }
}

.submitButton {
  display: block;
  max-width: 220px;
  font-family: var(--fontFamilyAvenir);
  min-height: 47px;
  background-color: #023462;
  color: #eee;
  border-radius: 7px;
  margin: 45px auto 30px 0;
  font-size: 21px;
  font-weight: 500;
  @media (max-width: 767px) {
    margin: 25px auto 10px 0;
    font-size: 18px;
  }
  @media (max-width: 430px) {
    max-width: 100%;
  }
}
.radinp {
  display: none;
}
.selectbtn {
  display: inline-flex;
  background-color: #023462;
  color: #fffffd;
  border-radius: 5px;
  text-align: center;
  font-family: var(--fontFamilyAvenir);
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  cursor: pointer;
}
.singlePackages ul {
  padding-left: 15px;
}
.singlePackages ul li {
  list-style: disc;
  color: #292929;
  line-height: 18px;
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 14px !important;
}
.actpkg {
  border: 1px solid #023462 !important;
}
.singlePackages:hover .selectbtn {
  background-color: #000000;
}
