@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}
.hidebtn {
  visibility: hidden;
}
.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.mapPanel {
}

.title {
  composes: formMargins;
  /* flex-basis: 7%; */
  padding: 4px 0px 0 5px;
  position: relative;
  min-height: 50px;
  margin: 15px 0 0 0;
  & label {
    background-color: white;
    padding-left: 3px;
    padding-right: 3px;
    position: absolute;
    top: -4px;
    left: 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--matterColorGrey);
    margin-left: 15px;
  }
  & input,
  & textarea {
    border-bottom: none;
    font-size: 15px;
    font-weight: 300;
    background-color: white;
    padding-top: 0;
    padding: 8px 12px;
    border: 1px solid var(--marketplaceColor);
    transition: none;
    border-radius: var(--borderRadius8);
  }
  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
  & textarea {
    box-sizing: border-box;
    min-height: 136px !important;
    margin: 0px !important;
    padding: 12px !important;
    border: 1px solid var(--marketplaceColor);
    resize: vertical !important;
    /* height: unset !important; */
  }
  @media (max-width: 991px) {
    margin: 16px 0px 0;
  }
}
.invalidInput {
  & input,
  & textarea {
    border-color: var(--failColor);
  }
  & label {
    color: var(--failColor);
  }
}
.errorMessage {
  & > div:last-child {
    font-size: 14px !important;
    color: var(--failColor);
    font-weight: 400 !important;
    margin-top: 14px !important;
    margin-bottom: 0px !important;
    position: static !important;
  }
}

.description {
  composes: formMargins;
  /* flex-shrink: 0; */
  /* flex-basis: 12%; */
}
.helpText {
  color: var(--matterColorBlack);
  font-size: 13px;
  line-height: 17px;
  margin-left: 4px;
  font-weight: 400;
  display: block;
  margin-top: 10px;
  @media (max-width: 991px) {
    margin-top: 8px;
  }
}
.messageNone {
  margin-top: 5px;
  margin-left: 9px;
  /* text-align: right; */
  /* @media (max-width: 767px) {
    margin-top: 0 !important;
  } */
}

.selectDiv {
  position: relative;
  margin: 15px 0px;

  & .selectLabel {
    position: absolute;
    top: -7px;
    left: 16px;
    z-index: 1;
    background: #fff;
    display: inline-block;
    padding: 0px 4px;
    font-size: 14px;
    line-height: 17px;
    color: var(--matterColorGrey);
    font-weight: 500;
  }
  & p {
    font-size: 14px;
    margin-top: -3px;
    margin-left: 3px;
  }
  & .lockMessage {
    display: flex;
    /* flex-direction: row; */
    & svg {
      margin-top: 4px;
    }
    & p {
      margin-left: 6px;
    }
  }
}
.countryState {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
  padding-top: 20px;
}
.selectDivCountry {
  position: relative;
  flex-basis: 45%;

  & .selectLabel {
    position: absolute;
    top: -7px;
    left: 16px;
    z-index: 1;
    background: #fff;
    display: inline-block;
    padding: 0px 4px;
    font-size: 14px;
    line-height: 17px;
    color: var(--matterColorGrey);
    font-weight: 500;
  }
  & p {
    font-size: 14px;
    margin-top: -3px;
    margin-left: 3px;
  }
  & .lockMessage {
    display: flex;
    /* flex-direction: row; */
    & svg {
      margin-top: 4px;
    }
    & p {
      margin-left: 6px;
    }
  }
}
.optionControl {
  border: 1px solid var(--marketplaceColor) !important;
  min-height: 48px !important;
  border-radius: var(--borderRadius8) !important;
  padding-left: 4px;
  width: calc(100% - 4px);
  margin-left: 4px;
  font-size: 16px;
}
.emptyOptionControl {
  & > div:first-child {
    & > div:first-child {
      color: var(--matterColorGrey);
    }
  }
}

.focusedControl {
  border: 1px solid var(--marketplaceColor) !important;
  box-shadow: none !important;
}
.selectMenu {
  z-index: 10 !important;
  border-radius: var(--borderRadius10) !important;
  box-shadow: var(--boxShadowDropDown) !important;
  top: unset !important;
  margin: 0px !important;

  & > div:first-child {
    padding: 0px !important;
    border-radius: var(--borderRadius10) !important;
    & .singleOption:last-child {
      &::after {
        display: none;
      }
    }

    & .singleOption:first-child {
      border-top-left-radius: var(--borderRadius10);
      border-top-right-radius: var(--borderRadius10);
    }

    & .singleOption:last-child {
      border-bottom-left-radius: var(--borderRadius10);
      border-bottom-right-radius: var(--borderRadius10);
    }
  }
}
.singleOption {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: var(--matterColorBlack) !important;
  font-family: var(--fontFamilyAvenir) !important;
  padding: 16px 26px !important;
  position: relative !important;
  background-color: #fff !important;
  flex-grow: 1;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 48px);
    height: 1px;
    background: var(--matterColorBlue) !important;
    z-index: 1;
  }
}
.multiOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative !important;
  padding: 18px 28px !important;
  background-color: #fff !important;

  & .mutliOptnLabel {
    margin-left: 8px;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: var(--matterColorBlack) !important;
    font-family: var(--fontFamilyAvenir) !important;
    line-height: 20px;
    position: relative;
    top: 3px;

    flex-grow: 1;
  }

  & .checkbox {
    width: 20px;
    height: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 48px);
    height: 1px;
    background: var(--matterColorBlue) !important;
    z-index: 1;
  }
}
.selectedMutliOption {
  background-color: var(--matterColorBlue) !important;
  margin-top: -1px !important;
}
.valueContainer {
  margin: 8px 0px 4px !important;

  & .value:nth-child(n + 3) {
    display: none;
  }
}
.moreThanTwoValueContainer {
  & > .value:nth-child(2) {
    margin-right: 24px;
    &::after {
      content: '...';
      position: absolute;
      bottom: 2px;
      right: -24px;
      font-size: 36px;
      color: var(--matterColorBlack);
    }
  }
}

.value {
  position: relative !important;
  & > div {
    background-color: var(--successColor) !important;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: var(--borderRadius5) !important;
    overflow-y: hidden;
    margin-right: 8px !important;

    & > div:first-child {
      color: #fff !important;
      font-family: var(--fontFamilyAvenir) !important;
      font-weight: 600 !important;
      letter-spacing: 0.2px !important;
      position: relative;
      top: 1px;
    }

    & > div:last-child {
      background-color: var(--matterColorYellow) !important;
      padding: 2px !important;
      margin: 0px 4px;
      border-radius: 50% !important;
      & svg {
        width: 16px;
        height: 16px;
        fill: var(--successColor) !important;
      }
    }
  }
}

.emptyValueContainer {
  & > div:first-child {
    background-color: transparent !important;
  }
}

.dropDownIndicator {
  & svg {
    width: 24px !important;
    height: 24px !important;
    fill: var(--matterColorBlack) !important;

    & path {
      stroke: var(--matterColorBlack) !important;
    }
  }
}

.selectedSingleOption {
  background-color: var(--matterColorBlue) !important;
  margin-top: -1px !important;
}

/* .submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
} */
.submitButton {
  font-size: 16px;
  font-weight: 100;
  margin-top: 35px;
  margin-bottom: 0;
  width: 181px;
  border-radius: 5px;
  min-height: 48px;

  @media (max-width: 767px) {
    margin-top: 25px;
  }
  @media (max-width: 375px) {
    width: 100%;
  }
}

.city {
  padding: 4px 0px 0 5px;
  position: relative;
  flex-basis: 48%;

  & label {
    background-color: white;
    padding-left: 3px;
    padding-right: 3px;
    position: absolute;
    top: -11px;
    left: 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--matterColorGrey);
    margin-left: 15px;
  }
  & input,
  & textarea {
    border-bottom: none;
    font-size: 15px;
    font-weight: 300;
    background-color: white;
    padding-top: 0;
    padding: 8px 12px;
    border: 1px solid var(--marketplaceColor);
    transition: none;
    border-radius: var(--borderRadius8);
  }
  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
  & textarea {
    box-sizing: border-box;
    min-height: 136px !important;
    margin: 0px !important;
    padding: 12px !important;
    border: 1px solid var(--marketplaceColor);
    resize: vertical !important;
    height: unset !important;
  }
  & input {
    line-height: 30px;
  }
}
.loc {
  color: #292929;
  font-size: 25px;
  font-family: var(--fontFamilyAvenir);
  margin-left: 9px;
  @media (--viewportLarge) {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
    display: block;
  }
}
.adddiv {
  margin: 15px 0 0;
  padding-top: 0;
}
.flddiv {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  @media (max-width: 375px) {
    flex-direction: column;
    margin: 20px 0 10px 0;
  }
  & div {
    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
  }
}
.mapButton {
  border: none;
  color: #025196;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5px;
  transition: all 400ms ease-in-out;
  & > svg {
    @media (max-width: 767px) {
      margin-right: 5px;
      margin-top: -5px;
    }
  }
  & > span {
    text-decoration: none;
  }
  &:hover {
    color: #292929;
    & > span {
      text-decoration: underline;
      text-underline-offset: 0.4em;
    }
  }
}
.mapButton span {
  font-size: 17px;
  font-weight: 500;
  @media (max-width: 575px) {
    font-size: 14px;
  }
}
.map {
  height: 370px;
  width: 100%;
}
.modalContainer h2 {
  color: #292929;
  font-size: 30px;
  margin: 0 0 5px;
  font-family: 'Josefin Sans';
  @media (max-width: 575px) {
    font-size: 22px;
  }
}
.modalContainer p {
  margin-top: 0;
  color: #292929;
  font-family: 'Avenir';
}

.modalContainer {
  background: #fff;
  padding: 20px;
  display: flex;
  margin: auto;
  position: relative;
  min-height: 500px;
  border-radius: 8px;
}
.garageAddress {
  position: absolute;
  display: flex;
  top: 114px;
  z-index: 99;
  right: 30px;
  align-items: center;
}
.garageAddress input {
  background: #ffffff;
  border-radius: 6px;
  border: 3px solid #4d85b545;
  min-width: 25vw;
  padding: 5px 10px 5px 30px;
  @media (max-width: 767px) {
    min-width: 55vw;
  }
}
.garageAddress svg {
  position: absolute;
  left: 12px;
  fill: #b9b9b9;
}
.MapLogo {
}
.zipFormatText {
  color: var(--matterColorBlack);
  font-size: 13px;
  line-height: 17px;
  margin-left: 4px;
  font-weight: 400;
  display: block;
  margin-top: 10px;
}
