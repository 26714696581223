@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 48px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
  @media(max-width:767px){
    margin-bottom:0;
  }
}

.error {
  color: var(--failColor);
}

.deliveryOption {
  margin-left: 30px;
  @media (--viewportMedium) {
    margin-left: 60px;
  }
}

.deliveryCheckbox {
  margin-bottom: 24px;
}

.disabled {
  color: var(--matterColorAnti);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.input {
  flex-shrink: 0;
  margin: 16px 0px;
  position: relative;

  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border: 1px solid var(--marketplaceColor);
    padding: 12px 18px;
    box-sizing: border-box;
    border-radius: var(--borderRadius8);
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorBlack);
  }
  & label {
    font-weight: 400 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: var(--matterColorGrey);
    font-size: 14px !important;
  }
  & textarea {
    min-height: 136px;
    resize: vertical !important;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}

.invalidInput {
  & input,
  & textarea {
    border-color: var(--failColor);
  }
  & label {
    color: var(--failColor);
  }
}

.submitButton {
  font-size: 16px;
  font-weight: 100;
  margin-top: 50px;
  margin-bottom: 56px;
  width: 181px;
  border-radius: 5px;
  min-height: 48px;
  @media (max-width: 375px) {
    width: 100%;
  }
}

.selectDiv {
  position: relative;
  margin: 20px 0px;

  & .selectLabel {
    position: absolute;
    top: -7px;
    left: 16px;
    z-index: 1;
    background: #fff;
    display: inline-block;
    padding: 0px 4px;
    font-size: 14px;
    line-height: 17px;
    color: var(--matterColorGrey);
    font-weight: 400 !important;
  }
}

.singleOption,
.Control {
  font-weight: 400 !important;
}

/* .Control{
  border-radius: var(--borderRadius10) !important;
} */

.spanDiv {
  position: relative;
  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadius8);
  padding: 14px 18px;
  margin: 16px 0px;
  color: var(--matterColorBlack);
  font-weight: 400;
  font-size: 16px;
  opacity: 0.5;
  max-height: 54px;

  & .label {
    position: absolute;
    font-size: 14px;
    line-height: 17px;
    background: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: var(--matterColorGrey);
  }
}
.spanDiv span {
  @media (max-width: 575px) {
    max-width: 100%;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.numberPlaceholder {
  display: flex;
  flex-direction: row;
  color: var(--matterColorBlack);
  font-weight: 400;
  line-height: 17px;
  font-size: 13px;
  margin-left: 4px;
  margin-top: -8px;
  margin-bottom: 16px;

  & svg {
    margin-right: 8px;
    flex-shrink: 0;
  }
}

.ValueContainer {
  padding-top: 0px !important;
  padding-left: 12px !important;
}
.invValCon{
  padding-top: 0px !important;
  padding-left: 0px !important;
}

.emailPlaceholder {
  display: flex;
  flex-direction: row;
  margin-left: 4px;
  margin-top: -8px;
  margin-bottom: 16px;

  & .message {
    display: flex;
    flex-direction: row;
    color: var(--matterColorBlack);
    font-weight: 400;
    line-height: 17px;
    font-size: 13px;
    flex-grow: 1;
    margin-right: 8px;

    & svg {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
  & .status {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    line-height: 17px;
    font-size: 14px;
    text-transform: capitalize;
    color: var(--matterCOlorDarkGrey);

    & svg {
      margin-right: 4px;
      flex-shrink: 0;
    }
  }

  & .unverified {
    color: var(--failColor);

    & svg {
      & g {
        & circle {
          fill: var(--failColor);
        }
      }
    }
  }
}
.textareaDiv{
  padding: 20px 20px 20px;
  border: 1px solid black;
  border-radius: 10px;
  position: relative;
  & label{
    font-weight: 400 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: var(--matterColorGrey);
    font-size: 14px !important;
  }
  & span{
    color: var(--matterColorBlack);
    font-weight: 400;
    line-height: 30px;
    font-size: 16px;
  }
  & input{
    border-bottom: 1px solid var(--matterColorGrey);
  }
  & textarea{
    border-bottom: 1px solid var(--matterColorGrey);
  }
}
.invrng{
  border: none;
}
.rngeselect{
  border-bottom: 1px solid var(--matterColorGrey) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}
.focusedControl{
  border-bottom: 1px solid var(--matterColorGrey) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: none !important; 
}
.MapIcon{
  
}