@import '../../../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.thumbnail {
  margin: 0 15px 15px 0;
  border-radius: 13px;
  width: 31%;
  overflow: visible;
  @media (max-width: 767px) {
    margin: 0 10px 15px 0;
  }
  @media (max-width: 575px) {
    width: 45%;
  }
}

.addImageWrapper {
  position: relative;
  width: 31%;
  margin: 0 15px 15px 0;
  overflow: hidden;
  @media (max-width: 575px) {
    width: 45%;
  }
  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  /* @media (min-width: 375px) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;
  } */
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: solid;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 13px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 10px;
  font-size: 50px;
  font-weight: 200;
  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 10px;
    /* font-size: 70px; */
    font-weight: 200;
  }
  @media (max-width: 375px) {
    margin-bottom: 4px;
    /* font-size: 55px; */
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  padding: 0 5px;
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (max-width: 575px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.addImageInput {
  display: none;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.arrayError {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--failColor);
  width: 100%;
  clear: both;

  margin-top: 6px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.submitButton {
  font-size: 16px;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 0;
  width: 181px;
  border-radius: 5px;
  min-height: 48px;
}
.upload {
  font-size: 16px;
  font-weight: 600;
  border: 1px solid var(--marketplaceColor);
  display: inline-block !important;
  min-height: auto !important;
  max-width: 190px;
  padding: 10px 12px;
  border-radius: 8px;
  /* @media (max-width: 767px) {
    max-width: none;
  } */
}
.privateMsgDiv {
  color: var(--matterColorBlack);
  font-size: 13px;
  line-height: 17px;
  margin-top: -8px;
  margin-left: 4px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & svg {
    margin-right: 8px;
  }
}
.docsec {
  & h2 {
    margin: 20px 0;
    line-height: 13px;
    font-family: var(--fontFamilyAvenir);
    font-size: 25px;
    @media (--viewportLarge) {
      font-size: 30px;
      font-weight: 600;
    }
    @media (max-width: 575px) {
      font-size: 20px;
      margin: 15px 0;
    }
  }
  & span {
    margin-bottom: 16px;
    display: block;
  }
}
.fileup {
  /* margin-bottom: 10px; */
  & > section {
    & > section {
      & > div {
        @media (max-width: 767px) {
          align-items: inherit;
        }
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}
.fileup p {
  margin: 0 0 0 15px;
  font-size: 15px;
  line-height: 24px;
  @media (max-width: 767px) {
    margin: 15px 0 0 15px;
    font-size: 14px;
  }
}
.lictnt {
  display: flex;
  align-items: center;
}
.lictnt p {
  text-align: center;
  font-size: 14px !important;
  font-weight: 600 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
  margin: 0 20px 0 0;
}
.lisvg {
  background: #fdb338;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.alrupd {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
}
.alrupd li {
  margin: 0 5px 5px;
  border-radius: 10px;
  box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  justify-content: center;
  @media (max-width: 767px) {
    margin: 0 5px 15px;
    /* width: 100%; */
  }
}
.errorMsg {
  composes: h4 from global;
  margin-top: 10px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden;
}
.discoverMore {
  color: #025196;
  /* display: table; */
  font-weight: 800;
  /* margin: 40px auto 10px; */

  /* font-size: 13px; */

  border-bottom: 2px solid #025196;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #000000;
    border-bottom: 2px solid #000000;
  }
  /* text-decoration: underline; */
  @media (max-width: 767px) {
    margin: 40px 0 10px;
  }
}
.errorTxt {
  color: var(--failColor);
}
