@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;
}

/* @media (--viewportMedium) {
  .root {
    margin-top: 48px;
  }
} */

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }

  & h3{
    color:var(--matterColorBlack);
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
  color:var(--matterColorBlack);

  & label{
    color:var(--matterColorBlack) !important;
  }
  
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}

.selectCountry {
  border: 1px solid #023462;
  border-radius: 8px;
  height: 50px;
  margin-bottom: 35px;
  
  & label {
    position: absolute;
    background-color: white;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: -11px;
    font-size: 12px;
    font-weight: 300;
    color: #b9b9b9;
    margin-left: 12px;
  }
  & select {
    border-bottom: none;
    font-size: 15px;
    font-weight: 300;
    background-color: white;
    padding-top: 0;
    margin: 7px -5px;
    padding-left: 17px;
    background-color: transparent;
    background-position:97%;
  }
}

.invalidSelectCountry{
  border-color: var(--failColor);
  & label{
    color:var(--failColor);
  }

  & > div:last-child{
    font-weight: 400;
    font-size: 14px;
    margin-top: 12px;
  }
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  margin-bottom: 12px;
  text-align: center;

  
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField {
  border: 1px solid #023462;
  border-radius: 8px;
  height: 50px;
  margin-bottom: 35px;
  & label {
    position: absolute;
    background-color: white;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: -11px;
    font-size: 12px;
    font-weight: 300;
    color: #b9b9b9;
    margin-left: 12px;
  }
  & input {
    border-bottom: none;
    font-size: 15px;
    font-weight: 300;
    background-color: white;
    padding-top: 0;
    margin: 7px -5px;
    padding-left: 17px;
    background-color: transparent;
  }
}
.invalidInputs{
  border-color: #f24040 !important;
  & label {
    color: #f24040 ;
  }
}
/* .bankDetailsStripeField p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
/* margin-top: 12px;
  margin-bottom: 12px; */
/* 
  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium); */
/* margin-top + n * line-height + margin-bottom => x * 8px */
/* margin-top: 17px;
    margin-bottom: 15px; */
/* }
} */
.missingStripeKey {
  color: var(--failColor);
}

.accountInformationTitle {
  composes: h3 from global;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (--viewportMedium) {
  .accountInformationTitle {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedCountry {
  padding-top: 6px;
  padding-bottom: 0px;
}
@media (--viewportMedium) {
  .savedCountry {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedInformation {
  margin-bottom: 24px;
}

.savedBankAccount {
  width: 100%;
  border-bottom: 2px solid var(--successColor);
  margin-bottom: 24px;
  padding: 4px 0 10px 0;
  color: var(--matterColor);
  text-align: left;

  &:hover {
    cursor: text;
    text-decoration: none;
    border-color: var(--matterColor);
  }

  &:focus {
    outline: none;
    border-color: var(--matterColor);
  }
}


.submitButton{
  min-height: 48px;
  border-radius: 4px;
  font-weight: 400;
}