@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  margin-top: -18px;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 100%;
  margin-top: -8px;

  & input,
  & select{
    border-bottom: 0px;
    border: 1px solid #023462;
    border-radius: 8px;
    height: 50px;
    margin-bottom:4px;
    padding: 12px 16px!important;
  }

  & select{
    background-position: 95%;
  }

  & label{
    position: relative;
    top:16px;
    left:12px;
      background-color:#fff;
      padding:4px 6px;
      font-size: 12px;
      font-weight: 500;
      color: #b9b9b9;
      display: inline-block;
  
  }

  & > div:last-child{
    font-size: 14px;
    font-weight: 400;
  }

  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}

.invalidField{
  & input,
  & select{
    border-color:var(--failColor);
  }
  & label{
    color:var(--failColor);
  }
}
