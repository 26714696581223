@import '../../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.title {
  margin: 10px 0 19px 0;
  font-family: var(--fontFamilyAvenir);
  font-size: 25px;

  @media (--viewportLarge) {
    margin-bottom: -5px;
    padding: 1px 0 7px 0;
    font-size: 30px;
    font-weight: 600;
    margin-top: -25px;
  }
  @media(max-width:1023px){
    margin: 0 ;
  }
  @media(max-width:575px){
    font-size: 22px;
  }
}
